<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Consider the reaction shown below to determine which direction the equilibrium would shift
        in response to each of the disturbances described here:
      </p>

      <p class="mb-5 pl-12">
        <number-value :value="equivsA" />
        <chemical-latex content="A(g) <=> " />
        <number-value :value="equivsB" />
        <chemical-latex content="B(g) +" />
        <number-value :value="equivsC" />
        <stemble-latex content="$\text{C(g)}\hspace{1.0cm}\Delta\text{H}=$" />
        <number-value :value="enthalpy" unit="\text{kJ/mol}" />
      </p>

      <p class="mb-0">
        a) Which direction will the equilibrium shift if
        <chemical-latex :content="compoundChange1" />
        is <b>decreased</b>?
      </p>

      <v-radio-group v-model="inputs.input1" class="pl-6 mt-1 mb-2" :disabled="!allowEditing">
        <div v-for="option in options" :key="option.value">
          <v-radio class="d-inline-block" :value="option.value" />
          <stemble-latex :content="option.condition" />
        </div>
      </v-radio-group>

      <p class="mb-0">
        b) Which direction will the equilibrium shift if the
        <b>{{ changeInPV }}</b> at constant temperature?
      </p>

      <v-radio-group v-model="inputs.input2" class="pl-6 mt-1 mb-2" :disabled="!allowEditing">
        <div v-for="option in options" :key="option.value">
          <v-radio class="d-inline-block" :value="option.value" />
          <stemble-latex :content="option.condition" />
        </div>
      </v-radio-group>

      <p class="mb-0">
        c) Which direction will the equilibrium shift if the
        <b>temperature is {{ changeInT }}</b
        >?
      </p>

      <v-radio-group v-model="inputs.input3" class="pl-6 mt-1 mb-n4" :disabled="!allowEditing">
        <div v-for="option in options" :key="option.value">
          <v-radio class="d-inline-block" :value="option.value" />
          <stemble-latex :content="option.condition" />
        </div>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ChemicalLatex from '../displayers/ChemicalLatex';
import StembleLatex from '@/tasks/components/StembleLatex';
import NumberValue from '../NumberValue';

export default {
  name: 'Question175a',
  components: {ChemicalLatex, StembleLatex, NumberValue},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
        input2: null,
        input3: null,
        input4: null,
      },
      options: [
        {
          condition: '$\\text{Shift left (form more reactants)}$',
          value: 'left',
        },
        {
          condition: '$\\text{Shift right (form more products)}$',
          value: 'right',
        },
        {condition: '$\\text{No change}$', value: 'noChange'},
        {
          condition: '$\\text{More information is required}$',
          value: 'unknown',
        },
      ],
    };
  },
  computed: {
    RorP1() {
      return this.taskState.getValueBySymbol('RorP1').content;
    },
    Tdirection() {
      return this.taskState.getValueBySymbol('Tdirection').content;
    },
    PVdirection() {
      return this.taskState.getValueBySymbol('PVdirection').content;
    },
    enthalpy() {
      return this.taskState.getValueBySymbol('enthalpy').content;
    },
    compoundChange1() {
      if (this.RorP1.value === 1) {
        return '[A]';
      } else if (this.RorP1.value === 2) {
        return '[C]';
      } else {
        return null;
      }
    },
    compoundChange2() {
      if (this.RorP2.value === 1) {
        return 'B';
      } else {
        return null;
      }
    },
    changeInT() {
      if (this.Tdirection.value === 1) {
        return 'increased';
      } else if (this.Tdirection.value === 2) {
        return 'decreased';
      } else {
        return null;
      }
    },
    changeInPV() {
      if (this.PVdirection.value === 1) {
        return 'pressure of the system is increased';
      } else if (this.PVdirection.value === 2) {
        return 'pressure of the system is decreased';
      } else if (this.PVdirection.value === 3) {
        return 'volume of the system is increased';
      } else if (this.PVdirection.value === 4) {
        return 'volume of the system is decreased';
      } else {
        return null;
      }
    },
    equivsA() {
      return this.taskState.getValueBySymbol('equivsA').content;
    },
    equivsB() {
      return this.taskState.getValueBySymbol('equivsB').content;
    },
    equivsC() {
      return this.taskState.getValueBySymbol('equivsC').content;
    },
  },
};
</script>
